import React from "react"
import { Link, navigate } from "gatsby"

export default function Layout({ children, location }) {
  const langSwither = lang => {
    localStorage.setItem("gatsby-i18next-language", lang)
    if (lang === "en") {
      window.location.href = location.href.replace("/th", "")
    } else {
      window.location.href = location.href
    }
  }
  const lang = localStorage.getItem("gatsby-i18next-language")
  return (
    <div lang={lang}>
      <header>
        <div className="container">
          <div className="flex justify-between items-center py-5 uppercase">
            <div>
              <Link to="/">
                <img src="/logo.png" alt="" />
              </Link>
            </div>
            <ul className="space-y-3">
              <li>
                <Link to="/services/leadership">SERVICE</Link>
              </li>
              <li>
                <Link to="/about-us">ABOUT US</Link>
              </li>
              <li>
                <Link to="/article-page?category=1">
                  ARTICLES <br />& RESOURCES
                </Link>
              </li>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
              <li className="space-x-1">
                <span
                  style={{
                    textDecoration: lang === "en" ? "underline" : "none",
                  }}
                  className="cursor-pointer"
                  onClick={() => langSwither("en")}
                >
                  EN
                </span>
                <span>|</span>
                <span
                  style={{
                    textDecoration: lang === "th" ? "underline" : "none",
                  }}
                  className=" cursor-pointer"
                  onClick={() => langSwither("th")}
                >
                  TH
                </span>
              </li>
            </ul>
          </div>
        </div>
      </header>
      <div className="min-h-screen">{children}</div>

      <footer>
        <img className="w-full" src="/footer-bg.png" />
      </footer>
    </div>
  )
}
